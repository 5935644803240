var styles = {
  "wrapper": "styles-module_wrapper__-on2C",
  "userNavContainer": "styles-module_userNavContainer__4ypeL",
  "container": "styles-module_container__xcFcI",
  "default": "styles-module_default__rGFcJ",
  "admin": "styles-module_admin__0Qh7Q",
  "education": "styles-module_education__W4M8M",
  "local": "styles-module_local__oZPtA",
  "logoAndNavItemsContainer": "styles-module_logoAndNavItemsContainer__E6O1L",
  "utilityItemsAndProfileItemContainer": "styles-module_utilityItemsAndProfileItemContainer__-SBaN",
  "navList": "styles-module_navList__ckDmD",
  "navigationItem": "styles-module_navigationItem__kcMrY",
  "hasDropdown": "styles-module_hasDropdown__b5vm7",
  "tier4_lowerFontSize": "styles-module_tier4_lowerFontSize__eUnoP",
  "tier5_lowestFontSize": "styles-module_tier5_lowestFontSize__6utNC",
  "active": "styles-module_active__lo-Rt",
  "navItemText": "styles-module_navItemText__00vcH",
  "navItemHoverArea": "styles-module_navItemHoverArea__ScNZX",
  "navItemChevron": "styles-module_navItemChevron__4ohoF",
  "divider": "styles-module_divider__3vv7i",
  "caIconContainer": "styles-module_caIconContainer__Wxynb",
  "caLogoFocusRingContainer": "styles-module_caLogoFocusRingContainer__Zs5op",
  "caLogoContainer": "styles-module_caLogoContainer__9wNo7",
  "utilityButton": "styles-module_utilityButton__wFJ4O",
  "utilityButtonIcon": "styles-module_utilityButtonIcon__Eb6VX",
  "utilityButtonLabel": "styles-module_utilityButtonLabel__7w7-r",
  "utilityItem": "styles-module_utilityItem__vTPVC",
  "profileMenu": "styles-module_profileMenu__XKN5H",
  "superuserMenu": "styles-module_superuserMenu__P2rMj"
};
export { styles as default };
