var styles = {
  "sidePanel": "NavigationMobile-module_sidePanel__pTfvM",
  "backdropLayer": "NavigationMobile-module_backdropLayer__gQBCS",
  "bodyContainer": "NavigationMobile-module_bodyContainer__hszLH",
  "body": "NavigationMobile-module_body__lfB0H",
  "header": "NavigationMobile-module_header__o6Gn4",
  "headingWrapper": "NavigationMobile-module_headingWrapper__U3UKO",
  "separatorXl": "NavigationMobile-module_separatorXl__mq1bS",
  "innerWrapper": "NavigationMobile-module_innerWrapper__ceI-E",
  "navList": "NavigationMobile-module_navList__UExv5",
  "menuOrLink": "NavigationMobile-module_menuOrLink__HilkJ",
  "active": "NavigationMobile-module_active__wxt-K",
  "menuArrow": "NavigationMobile-module_menuArrow__uZ9KN",
  "leafLinkIcon": "NavigationMobile-module_leafLinkIcon__cZrU0",
  "group": "NavigationMobile-module_group__h-1or",
  "groupHeading": "NavigationMobile-module_groupHeading__CsT-M",
  "loadingIcon": "NavigationMobile-module_loadingIcon__vOUlu",
  "slideAnimation": "NavigationMobile-module_slideAnimation__harJM",
  "isClosed": "NavigationMobile-module_isClosed__GRqQ-",
  "isOpen": "NavigationMobile-module_isOpen__D8uGL",
  "fadeAnimation": "NavigationMobile-module_fadeAnimation__taqkU",
  "backdropHidden": "NavigationMobile-module_backdropHidden__y89th",
  "backdropShown": "NavigationMobile-module_backdropShown__oQ-7S",
  "visuallyHidden": "NavigationMobile-module_visuallyHidden__yCVe9"
};
export { styles as default };
