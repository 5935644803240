import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { Brand, IconButton, CaMonogramIcon, SpinnerIcon, SettingsIcon, NotificationIcon, MenuItem, ExternalLinkIcon, MenuList, MenuHeading, Menu as Menu$1, ChevronDownIcon, VisuallyHidden } from '@kaizen/components';
import { SkipLink } from '../SkipLink/SkipLink.mjs';
import { UtilityItem } from '../UtilityItems/UtilityItems.mjs';
import { ampli } from '../../ampli/index.mjs';
import { getHelpItem } from '../helpers/getHelpItem.mjs';
import { getLinkPropsFromFirstNavItem, isMenu, getPathnameOrHref, idIsActive, isMenuItemGroup, groupMenuItems, menuHasActiveChildItem } from '../helpers/uiHelpers.mjs';
import { httpRequest } from '../helpers/httpRequestHelper.mjs';
import { useMainContent } from '../hooks/useMainContent.mjs';
import ProfileMenu from '../ProfileMenu/ProfileMenu.mjs';
import React from 'react';
import SuperuserMenu from '../SuperuserMenu/SuperuserMenu.mjs';
import cx from 'classnames';
import styles from '../styles.module.scss.mjs';
import useResponsiveVariant from '../hooks/useResponsiveVariant.mjs';
const Link = ({
  activeRouteId,
  isMenuItem,
  link,
  onClick,
  groupTitle
}) => {
  const isActive = idIsActive(link.id, activeRouteId);
  const onClickWithTracking = event => {
    onClick && onClick(event);
    ampli.navigationBarClicked({
      "Menu item": link.id,
      "Menu header": groupTitle
    });
  };
  return isMenuItem ? jsx(MenuItem, {
    onClick: onClickWithTracking,
    label: link.title,
    href: getPathnameOrHref(link),
    isActive: isActive,
    target: link.isExternal ? "_blank" : undefined,
    icon: link.isExternal ? jsx(ExternalLinkIcon, {
      role: "presentation"
    }) : undefined
  }, link.id) : jsx("a", {
    className: cx(styles.navigationItem, isActive && styles.active),
    href: getPathnameOrHref(link),
    "aria-current": isActive ? "page" : undefined,
    onClick: event => {
      onClickWithTracking(event);
      if (link.method && !event.defaultPrevented) {
        event.preventDefault();
        httpRequest(link, {});
      }
    },
    children: jsx("span", {
      className: styles.navItemHoverArea,
      children: jsx("span", {
        className: styles.navItemText,
        children: link.title
      })
    })
  });
};
const Menu = ({
  menu,
  link,
  activeRouteId
}) => {
  const containsItemGroups = menu.menuItems.find(item => isMenuItemGroup(item));
  let menuContent;
  if (containsItemGroups) {
    menuContent = groupMenuItems(menu.menuItems).map(item => {
      return jsx(MenuList, {
        heading: (item === null || item === void 0 ? void 0 : item.groupTitle) ? jsx(MenuHeading, {
          tag: "h2",
          children: item === null || item === void 0 ? void 0 : item.groupTitle
        }) : jsx(Fragment, {}),
        children: item.menuItems.map(link)
      }, item.id);
    });
  } else {
    menuContent = jsx(MenuList, {
      children: menu.menuItems.map(item => {
        if (isMenuItemGroup(item)) {
          // Not possible because of the `containsItemGroups` check above, but TS can't work that out
          return;
        }
        return jsx(React.Fragment, {
          children: link(item)
        }, item.id);
      })
    });
  }
  const hasActiveChild = menuHasActiveChildItem(menu, activeRouteId);
  return jsx(Menu$1, {
    button: jsxs("button", {
      className: cx(styles.navigationItem, styles.hasDropdown, hasActiveChild && styles.active),
      children: [jsxs("span", {
        className: styles.navItemHoverArea,
        children: [jsx("span", {
          className: styles.navItemText,
          children: menu.title
        }), jsx("span", {
          className: styles.navItemChevron,
          children: jsx(ChevronDownIcon, {
            role: "presentation"
          })
        })]
      }), hasActiveChild && jsx(VisuallyHidden, {
        children: "current section"
      })]
    }),
    children: menuContent
  });
};
const RESPONSIVE_VARIANTS = ["tier5_lowestFontSize", "tier4_lowerFontSize", "tier3_noLogoOrUtilityText", "tier2_noLogoText", "tier1_fullSize"];
const NavigationBar = props => {
  const {
    navbarRef,
    logoAndNavItemsContainerRef,
    currentResponsiveVariant,
    isAbove
  } = useResponsiveVariant(props.textDirection, props.loading, ...RESPONSIVE_VARIANTS);
  const {
    navigationData,
    textDirection,
    loading,
    onNavigationLinkClick,
    extraUtility,
    variant = "default",
    skipLinkTargetId,
    onHelpClick
  } = props;
  const {
    navItems,
    helpItem,
    helpLabel
  } = navigationData;
  const homeLink = getLinkPropsFromFirstNavItem(navItems);
  const helpItemData = getHelpItem(helpItem, helpLabel);
  const HelpIcon = helpItemData.icon;
  const [mainNavRef, idOfMainContent] = useMainContent();
  return jsxs("div", {
    ref: mainNavRef,
    className: styles.wrapper,
    children: [jsx(SkipLink, {
      label: "Skip to content",
      skipTo: skipLinkTargetId || idOfMainContent
    }), jsx("nav", {
      dir: textDirection,
      ref: navbarRef,
      className: cx(styles.container, currentResponsiveVariant ? styles[currentResponsiveVariant] : null, styles[variant]),
      children: jsxs("div", {
        className: styles.userNavContainer,
        children: [jsxs("div", {
          className: styles.logoAndNavItemsContainer,
          ref: logoAndNavItemsContainerRef,
          children: [currentResponsiveVariant === "tier1_fullSize" ? jsx("a", {
            href: homeLink.href,
            className: styles.caLogoContainer,
            children: jsx("span", {
              className: styles.caLogoFocusRingContainer,
              children: jsx(Brand, {
                reversed: variant === "default" || variant === "local",
                variant: "logo-horizontal",
                alt: `Culture Amp ${homeLink.title}`
              })
            })
          }) : jsx("div", {
            className: styles.caIconContainer,
            children: jsx(IconButton, {
              reversed: variant === "default" || variant === "local",
              href: homeLink.href,
              icon: jsx(CaMonogramIcon, {
                role: "presentation"
              }),
              label: `Culture Amp ${homeLink.title}`
            })
          }), jsx("ul", {
            className: styles.navList,
            children: navigationData.navItems.map(item => {
              if (isMenu(item)) {
                return jsx("li", {
                  children: jsx(Menu, {
                    menu: item,
                    activeRouteId: props.activeRouteId,
                    link: link => jsx(Link, {
                      link: link,
                      isMenuItem: true,
                      onClick: onNavigationLinkClick,
                      activeRouteId: props.activeRouteId,
                      groupTitle: item.title
                    }, link.id)
                  })
                }, item.id);
              }
              return jsx("li", {
                children: jsx(Link, {
                  isMenuItem: false,
                  link: item,
                  onClick: onNavigationLinkClick,
                  activeRouteId: props.activeRouteId
                })
              }, item.id);
            })
          })]
        }), jsxs("div", {
          className: styles.utilityItemsAndProfileItemContainer,
          children: [extraUtility, loading && jsx("div", {
            className: styles.caIconContainer,
            children: jsx(SpinnerIcon, {
              role: "img",
              "aria-label": "Loading"
            })
          }), navigationData.settingsItem && jsx(UtilityItem, {
            id: navigationData.settingsItem.id,
            Icon: SettingsIcon,
            showLabel: isAbove("tier3_noLogoOrUtilityText"),
            title: navigationData.settingsItem.title,
            onClick: onNavigationLinkClick,
            href: getPathnameOrHref(navigationData.settingsItem),
            variant: variant
          }), onHelpClick && jsx(UtilityItem, {
            Icon: HelpIcon,
            showLabel: isAbove("tier3_noLogoOrUtilityText"),
            title: helpItemData.label,
            onClick: () => {
              onHelpClick();
              ampli.helpToolInitiated();
            },
            variant: variant
          }), navigationData.notificationsItem && jsxs(Fragment, {
            children: [jsx("span", {
              className: styles.divider
            }), jsx(UtilityItem, {
              id: navigationData.notificationsItem.id,
              Icon: NotificationIcon,
              showLabel: false,
              title: navigationData.notificationsItem.title,
              onClick: onNavigationLinkClick,
              href: getPathnameOrHref(navigationData.notificationsItem),
              variant: variant
            })]
          }), navigationData.superuserItem && jsx("div", {
            className: styles.superuserMenu,
            "data-automation-id": "SuperuserMenu",
            children: jsx(SuperuserMenu, {
              onLinkClick: onNavigationLinkClick,
              menuItems: navigationData.superuserItem.menuItems,
              variant: variant
            })
          }), navigationData.profileItem && jsx("div", {
            className: styles.profileMenu,
            "data-automation-id": "ProfileMenu",
            children: jsx(ProfileMenu, {
              ...navigationData.profileItem,
              onLinkClick: onNavigationLinkClick,
              variant: variant
            })
          })]
        })]
      })
    })]
  });
};
export { NavigationBar };
