import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useRef, useState, useEffect } from 'react';
import { SpinnerIcon, IconButton, CaMonogramIcon, Heading, ArrowBackwardIcon, CloseIcon, Divider, SettingsIcon, NotificationIcon, ArrowRightIcon, VisuallyHidden } from '@kaizen/components';
import { Transition } from '@headlessui/react';
import FocusLock from 'react-focus-lock';
import { createEventBus, useEventBus } from '@cultureamp/event-bus';
import { getLinkPropsFromFirstNavItem, isMenu, isMenuItemGroup, insertMenuItemSeparators, groupMenuItems, idIsActive, getPathnameOrHref, menuHasActiveChildItem } from '../helpers/uiHelpers.mjs';
import { httpRequest } from '../helpers/httpRequestHelper.mjs';
import cx from 'classnames';
import { ProfileBanner } from '../ProfileBanner/ProfileBanner.mjs';
import { ampli } from '../../ampli/index.mjs';
import { getHelpItem } from '../helpers/getHelpItem.mjs';
import createAriaHider from './createAriaHider.mjs';
import isChromatic from 'chromatic/isChromatic';
import styles from './NavigationMobile.module.scss.mjs';
const {
  subscribeFn,
  publishFn
} = createEventBus("TOGGLE_MOBILE_NAVIGATION");
const toggleMobileNavigation = publishFn;
const onMobileNavigationToggle = subscribeFn;
const closeMobileNavigation = () => toggleMobileNavigation("closed");
const Link = ({
  onClick,
  link,
  activeRouteId,
  Icon,
  groupTitle
}) => {
  const isActive = idIsActive(link.id, activeRouteId);
  return jsxs("a", {
    className: cx(styles.menuOrLink, isActive && styles.active),
    "aria-current": isActive ? "page" : undefined,
    onClick: event => {
      onClick && onClick(event);
      ampli.navigationBarClicked({
        "Menu item": link.id,
        "Menu header": groupTitle
      });
      if ("method" in link && link.method && !event.defaultPrevented) {
        event.preventDefault();
        httpRequest(link, {});
      }
    },
    href: getPathnameOrHref(link),
    children: [Icon && jsx("span", {
      className: styles.leafLinkIcon,
      children: jsx(Icon, {
        role: "presentation"
      })
    }), "mobileTitle" in link ? link.mobileTitle : link.title]
  });
};
const MenuTrigger = ({
  menu,
  onClick,
  activeRouteId
}) => {
  const hasActiveChild = menuHasActiveChildItem(menu, activeRouteId);
  return jsxs("button", {
    className: cx(styles.menuOrLink, hasActiveChild && styles.active),
    onClick: () => onClick(menu),
    children: [menu.title, jsx("span", {
      className: styles.menuArrow,
      children: jsx(ArrowRightIcon, {
        role: "presentation"
      })
    }), hasActiveChild && jsx(VisuallyHidden, {
      children: "current section"
    })]
  });
};
const MenuItemGroup = ({
  menuItemGroup,
  link
}) => jsxs("div", {
  className: styles.group,
  children: [(menuItemGroup === null || menuItemGroup === void 0 ? void 0 : menuItemGroup.groupTitle) && jsx("span", {
    className: styles.groupHeading,
    children: jsx(Heading, {
      tag: "p",
      variant: "heading-6",
      children: menuItemGroup.groupTitle
    })
  }), jsx("ul", {
    className: styles.navList,
    children: menuItemGroup.menuItems.map(link)
  })]
});
const NavigationMobile = ({
  navigationData,
  onNavigationLinkClick,
  activeRouteId,
  textDirection,
  onHelpClick,
  loading
}) => {
  const modalLayerRef = useRef(null);
  const headingRef = useRef(null);
  const subMenuHeadingRef = useRef(null);
  const mobileNavigationState = useEventBus(onMobileNavigationToggle, "closed");
  const [removeAriaHider, setRemoveAriaHider] = useState(null);
  const [currentMenu, setCurrentMenu] = useState("topLevel");
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === "Escape") closeMobileNavigation();
    }
    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
  }, []);
  useEffect(() => {
    var _a, _b;
    if (currentMenu !== "topLevel") {
      (_a = subMenuHeadingRef === null || subMenuHeadingRef === void 0 ? void 0 : subMenuHeadingRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    } else {
      (_b = headingRef === null || headingRef === void 0 ? void 0 : headingRef.current) === null || _b === void 0 ? void 0 : _b.focus();
    }
  }, [currentMenu]);
  const {
    helpItem,
    helpLabel
  } = navigationData;
  const helpItemData = getHelpItem(helpItem, helpLabel);
  const HelpIcon = helpItemData.icon;
  const onLinkClick = e => {
    closeMobileNavigation();
    onNavigationLinkClick && onNavigationLinkClick(e);
    // TODO: Design token to match animation speed.
    setTimeout(() => {
      setCurrentMenu("topLevel");
    }, isChromatic() ? 0 : 300);
  };
  // Renders level 1 of the navigation
  const renderTopLevelNavigationItems = navItems => jsxs(Fragment, {
    children: [jsx("ul", {
      className: styles.navList,
      children: navItems.map(navItem => {
        if (isMenu(navItem)) {
          return jsx("li", {
            children: jsx(MenuTrigger, {
              menu: navItem,
              activeRouteId: activeRouteId,
              onClick: setCurrentMenu
            }, navItem.title)
          }, navItem.id);
        }
        return jsx("li", {
          children: jsx(Link, {
            activeRouteId: activeRouteId,
            onClick: onLinkClick,
            link: navItem
          }, navItem.id)
        }, navItem.id);
      })
    }), (navigationData.settingsItem || navigationData.profileItem || navigationData.superuserItem || onHelpClick) && jsx("div", {
      className: styles.separatorXl,
      children: jsx(Divider, {
        variant: "menuSeparator"
      })
    }), jsxs("ul", {
      className: styles.navList,
      children: [navigationData.settingsItem && jsx("li", {
        children: jsx(Link, {
          Icon: SettingsIcon,
          onClick: onLinkClick,
          link: navigationData.settingsItem
        })
      }), onHelpClick && jsx("li", {
        children: jsxs("button", {
          onClick: () => {
            closeMobileNavigation();
            onHelpClick();
            ampli.helpToolInitiated();
          },
          className: styles.menuOrLink,
          children: [jsx("span", {
            className: styles.leafLinkIcon,
            children: jsx(HelpIcon, {
              role: "presentation"
            })
          }), helpItemData.label]
        })
      }), navigationData.notificationsItem && jsx("li", {
        children: jsx(Link, {
          Icon: NotificationIcon,
          onClick: onLinkClick,
          link: navigationData.notificationsItem
        }, navigationData.notificationsItem.id)
      }), navigationData.profileItem && jsx("li", {
        children: jsx(MenuTrigger, {
          menu: {
            id: "profile",
            title: navigationData.profileItem.title,
            menuItems: navigationData.profileItem.menuItems
          },
          activeRouteId: activeRouteId,
          onClick: setCurrentMenu
        })
      }), navigationData.superuserItem && jsx("li", {
        children: jsx(MenuTrigger, {
          menu: {
            id: "superuser",
            title: navigationData.superuserItem.title,
            menuItems: navigationData.superuserItem.menuItems
          },
          activeRouteId: activeRouteId,
          onClick: setCurrentMenu
        })
      })]
    })]
  });
  // Renders from level 2 of a specific Menu
  const renderSecondLevelNavigationItems = menu => {
    const menuItems = menu.menuItems;
    const containsItemGroups = menuItems.find(item => isMenuItemGroup(item));
    if (containsItemGroups) {
      return insertMenuItemSeparators(groupMenuItems(menuItems)).map(menuItem => {
        if (typeof menuItem === "string") return jsx(Divider, {
          variant: "menuSeparator"
        }, menuItem);
        return jsx(MenuItemGroup, {
          menuItemGroup: menuItem,
          link: link => jsx("li", {
            children: jsx(Link, {
              link: link,
              groupTitle: menu.title,
              activeRouteId: activeRouteId,
              onClick: onLinkClick
            })
          }, link.id)
        }, menuItem.id);
      });
    }
    return jsx("ul", {
      className: styles.navList,
      children: menuItems.map(menuItem => {
        if (isMenuItemGroup(menuItem)) {
          // not possible because of the `containsItemGroups` check above, but TS can't work that out
          return;
        }
        return jsx("li", {
          children: jsx(Link, {
            link: menuItem,
            activeRouteId: activeRouteId,
            groupTitle: menu.title,
            onClick: onLinkClick
          })
        }, menuItem.id);
      })
    });
  };
  const homeLink = getLinkPropsFromFirstNavItem(navigationData.navItems);
  return jsxs(Transition, {
    afterEnter: () => {
      var _a;
      (_a = headingRef === null || headingRef === void 0 ? void 0 : headingRef.current) === null || _a === void 0 ? void 0 : _a.focus();
      (modalLayerRef === null || modalLayerRef === void 0 ? void 0 : modalLayerRef.current) && setRemoveAriaHider(() => createAriaHider(modalLayerRef === null || modalLayerRef === void 0 ? void 0 : modalLayerRef.current, true));
    },
    afterLeave: () => {
      removeAriaHider && removeAriaHider();
      setCurrentMenu("topLevel");
    },
    show: mobileNavigationState === "open",
    children: [jsx(Transition.Child, {
      className: styles.backdropLayer,
      enter: styles.fadeAnimation,
      leave: styles.fadeAnimation,
      enterFrom: styles.backdropHidden,
      enterTo: styles.backdropShown,
      leaveFrom: styles.backdropShown,
      leaveTo: styles.backdropHidden,
      onClick: () => toggleMobileNavigation("closed")
    }), jsx(Transition.Child, {
      as: "nav",
      dir: textDirection,
      className: styles.sidePanel,
      enter: styles.slideAnimation,
      leave: styles.slideAnimation,
      enterFrom: styles.isClosed,
      enterTo: styles.isOpen,
      leaveFrom: styles.isOpen,
      leaveTo: styles.isClosed,
      children: jsxs(FocusLock, {
        returnFocus: true,
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus: false,
        ref: modalLayerRef,
        className: styles.innerWrapper,
        children: [jsxs("div", {
          className: styles.header,
          children: [currentMenu === "topLevel" ? jsxs(Fragment, {
            children: [loading ? jsx("span", {
              className: styles.loadingIcon,
              children: jsx(SpinnerIcon, {
                "aria-label": "Loading",
                role: "img"
              })
            }) : jsx(IconButton, {
              href: homeLink.href,
              reversed: true,
              label: `Culture Amp ${homeLink.title}`,
              icon: jsx(CaMonogramIcon, {
                role: "presentation"
              })
            }), jsx("div", {
              className: styles.headingWrapper,
              ref: headingRef,
              tabIndex: -1,
              children: jsx(Heading, {
                tag: "h2",
                variant: "heading-3",
                color: "white",
                children: navigationData.menuLabel || "Menu"
              })
            })]
          }) : jsxs(Fragment, {
            children: [jsx(IconButton, {
              reversed: true,
              onClick: () => setCurrentMenu("topLevel"),
              label: "Back to Main Menu",
              icon: jsx(ArrowBackwardIcon, {
                role: "presentation"
              })
            }), jsx("div", {
              ref: subMenuHeadingRef,
              tabIndex: -1,
              children: jsx(Heading, {
                tag: "h2",
                variant: "heading-3",
                color: "white",
                children: currentMenu.title
              })
            })]
          }), jsx(IconButton, {
            reversed: true,
            onClick: closeMobileNavigation,
            label: "Close Menu",
            icon: jsx(CloseIcon, {
              role: "presentation"
            })
          })]
        }), jsxs("div", {
          className: styles.bodyContainer,
          children: [jsx("div", {
            className: styles.body,
            children: currentMenu === "topLevel" ? renderTopLevelNavigationItems(navigationData.navItems) : renderSecondLevelNavigationItems(currentMenu)
          }), navigationData.profileItem && jsx(ProfileBanner, {
            ...navigationData.profileItem
          })]
        })]
      })
    })]
  });
};
export { NavigationMobile, onMobileNavigationToggle, toggleMobileNavigation };
