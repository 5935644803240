import { jsxs, jsx } from 'react/jsx-runtime';
import { ampli } from '../../ampli/index.mjs';
import cx from 'classnames';
import styles from '../styles.module.scss.mjs';
const UtilityItem = ({
  id,
  showLabel,
  href,
  onClick,
  title,
  Icon
}) => {
  const onClickWithTracking = event => {
    onClick && onClick(event);
    id && ampli.navigationBarClicked({
      "Menu item": id,
      "Menu header": undefined
    });
  };
  const itemClasses = cx(styles.utilityButton, styles.navigationItem);
  const itemInner = jsxs("span", {
    className: styles.navItemHoverArea,
    children: [jsx("span", {
      className: styles.utilityButtonIcon,
      children: showLabel ? jsx(Icon, {
        role: "presentation"
      }) : jsx(Icon, {
        role: "img",
        "aria-label": title
      })
    }), showLabel && jsx("span", {
      className: styles.utilityButtonLabel,
      children: title
    })]
  });
  return href ? jsx("a", {
    href: href,
    onClick: onClickWithTracking,
    className: itemClasses,
    children: itemInner
  }) : jsx("button", {
    onClick: onClickWithTracking,
    className: itemClasses,
    children: itemInner
  });
};
export { UtilityItem };
