import { jsx } from 'react/jsx-runtime';
import { IconButton, HamburgerIcon } from '@kaizen/components';
import styles from './LegacyMobileNavigationTrigger.module.scss.mjs';
const LegacyMobileNavigationTrigger = ({
  onTrigger
}) => {
  return jsx("div", {
    className: styles.legacyTrigger,
    children: jsx(IconButton, {
      label: "Open mobile navigation",
      onClick: () => onTrigger(),
      icon: jsx(HamburgerIcon, {
        role: "presentation"
      })
    })
  });
};
export { LegacyMobileNavigationTrigger };
