import { jsx, jsxs } from 'react/jsx-runtime';
import { Heading } from '@kaizen/components';
import styles from './ProfileBanner.module.scss.mjs';
const ProfileBanner = ({
  companyName,
  userName
}) => {
  return jsx("div", {
    className: styles.root,
    children: jsxs("div", {
      className: styles.userDetails,
      children: [jsx(Heading, {
        tag: "p",
        variant: "heading-6",
        color: "dark-reduced-opacity",
        children: jsx("span", {
          title: companyName,
          children: companyName
        })
      }), jsx(Heading, {
        tag: "p",
        variant: "heading-5",
        color: "dark",
        children: jsx("span", {
          title: userName,
          children: userName
        })
      })]
    })
  });
};
export { ProfileBanner };
