import { jsx } from 'react/jsx-runtime';
import { Menu, Avatar, MenuList, MenuItem } from '@kaizen/components';
import { getPathnameOrHref } from '../helpers/uiHelpers.mjs';
import cx from 'classnames';
import styles from './SuperuserMenu.module.scss.mjs';
const SuperuserMenu = props => {
  return jsx(Menu, {
    button: jsx("button", {
      className: cx(styles.avatarButton, props.variant === "education" && styles.education),
      title: "Profile",
      type: "button",
      children: jsx(Avatar, {
        fullName: "S",
        size: "medium",
        isCurrentUser: false
      })
    }),
    align: "right",
    children: jsx(MenuList, {
      children: props.menuItems.map(item => jsx(MenuItem, {
        label: item.title,
        onClick: props.onLinkClick,
        href: getPathnameOrHref(item)
      }, item.id))
    })
  });
};
export { SuperuserMenu as default };
