import { jsx, jsxs } from 'react/jsx-runtime';
import { SkipWhiteIcon } from '@kaizen/components';
import styles from './SkipLink.module.scss.mjs';
const SkipLink = ({
  label,
  skipTo
}) => jsx("a", {
  className: styles.skipLinkContainer,
  href: `#${skipTo}`,
  children: jsxs("span", {
    className: styles.skipLinkContent,
    children: [jsx("span", {
      className: styles.skipLinkIcon,
      children: jsx(SkipWhiteIcon, {
        role: "presentation"
      })
    }), jsx("span", {
      children: label
    })]
  })
});
export { SkipLink, SkipLink as default };
