import { jsx, Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import cx from 'classnames';
import styles from './CompanyLogo.module.scss.mjs';
const CompanyLogo = ({
  src,
  companyName,
  className
}) => {
  const [logoState, setLogoState] = useState(src ? "loading" : "none");
  const onImageFailure = () => setLogoState("error");
  const onImageSuccess = () => setLogoState("success");
  if (logoState === "none") return jsx(Fragment, {});
  if (logoState === "error") return jsx(Fragment, {});
  return jsx("div", {
    className: cx(styles.companyAvatar, className),
    children: jsx("img", {
      src: src,
      alt: `${companyName} logo`,
      onError: onImageFailure,
      onLoad: onImageSuccess
    })
  });
};
export { CompanyLogo };
